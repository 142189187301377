import { isBrowser, AVAILABLE_ENV, ENV, hostname } from './env';

const issuerDomain =
  // FIG QA
  hostname === 'qa.simon.figdevteam.com'
    ? 'https://auth.int.simon.figdevteam.com'
    : // FIG PROD
    hostname === 'simon.figmarketing.com'
    ? 'https://auth.simon.figmarketing.com'
    : // SIMON PROD
    ENV === AVAILABLE_ENV.PROD
    ? 'https://auth.simonmarkets.com'
    : // SIMON DEV&QA
      'https://auth.int.simonmarkets.com';

export const oktaConfig = {
  issuer:
    ENV === AVAILABLE_ENV.DEV
      ? `${issuerDomain}/oauth2/ausoudhup9cmqtoBP0h7`
      : ENV === AVAILABLE_ENV.QA
      ? `${issuerDomain}/oauth2/ausesty3dmANUL4lH0h7`
      : ENV === AVAILABLE_ENV.PROD
      ? `${issuerDomain}/oauth2/aus12xhtrm4lv59w32p7`
      : // default to Dev issuer
        `${issuerDomain}/oauth2/ausoudhup9cmqtoBP0h7`,
  redirectUri: isBrowser
    ? `${window.location.origin}${window.location.pathname}`
    : 'http://localhost:3000',
  clientId:
    ENV === AVAILABLE_ENV.PROD
      ? '0oadjggdmyOlW19D12p7'
      : '0oa11csbex44SlWOi0h8',
};

export const authUri = '/auth';
