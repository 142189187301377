import { shellUi } from '@icapitalnetwork/shell-ui';
import { CONFIG } from '@simon/config/env';
import shellPackage from '../package.json';
import { oktaConfig } from '@simon/config/auth';

const isDev = process.env.NODE_ENV === 'development';
// Detects if user is looking at FS Shell. TODO: Something smarter...
const isSIMONShellFS = location.pathname.includes(shellPackage.name);

shellUi({
  devMode: {
    enabled: isDev || isSIMONShellFS,
    position: 'bottom-left',
  },
  customProps: {
    routerMode: 'hash',
    auth: oktaConfig,
  },
  manifestUrl: isSIMONShellFS
    ? `/apps/${CONFIG.release}/manifest-v2.json`
    : '/apps/manifest-v2.json',
});
